var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex",staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"hidden md:block md:w-1/3 bg-white",style:("height: 100vh; background-image: url('/static/lif-logo-blanco.png'); background-size: 85% 50%; background-position: center; background-repeat: no-repeat;")}),_c('div',{staticClass:"relative block md:flex md:flex-col w-full md:w-2/3 center justify-center bg-jevi-dark overflow-y-auto"},[_c('div',{staticClass:"md:hidden"},[_c('router-link',{attrs:{"to":"/inicio"}},[_c('div',{staticClass:"w-1/5 my-4 mx-auto object-scale-down"},[_c('img',{attrs:{"src":"/static/lif-logo.png"}})])])],1),_c('div',{staticClass:"relative w-5/6 md:w-2/3 lg:w-7/12 mx-auto"},[_c('div',{staticClass:"relative w-full rounded-3xl p-8 bg-white shadow-md"},[_c('h1',{staticClass:"text-2xl text-center md:text-left md:text-5xl text-primary-800"},[_vm._v(" Reestablecer contraseña ")]),_c('div',{staticClass:"flex flex-col justify-center"},[(_vm.token)?[_c('p',{staticClass:"font-light text-center mt-6"},[_vm._v(" Ingresa tu nueva contraseña ")]),_c('div',{staticClass:"mt-8"},[_c('t-input-group',{staticClass:"mb-2 relative",attrs:{"feedback":_vm.showValidation && _vm.errors.first('password')
                      ? _vm.errors.first('password')
                      : '',"variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:6'),expression:"'required|min:6'"}],ref:"password",attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"password","placeholder":"Contraseña","autocomplete":"off","disabled":_vm.isLoading,"type":_vm.type,"variant":_vm.showValidation && _vm.errors.first('password') ? 'danger' : ''},model:{value:(_vm.recovery.password),callback:function ($$v) {_vm.$set(_vm.recovery, "password", $$v)},expression:"recovery.password"}}),_c('span',{staticClass:"absolute top-20 right-3 cursor-pointer",on:{"click":function($event){return _vm.showPassword('typeInput')}}},[_c('icon',{attrs:{"icon":_vm.typeInput === 'password' ? 'eye' : 'eye-slash'}})],1)],1),_c('t-input-group',{staticClass:"mb-2 relative",attrs:{"feedback":_vm.showValidation && _vm.errors.first('passwordConfirm')
                      ? _vm.errors.first('passwordConfirm')
                      : '',"variant":_vm.showValidation && _vm.errors.first('passwordConfirm')
                      ? 'danger'
                      : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:password|min:6'),expression:"'required|confirmed:password|min:6'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4 bg-transparent","name":"passwordConfirm","placeholder":"Confirmar contraseña","autocomplete":"off","disabled":_vm.isLoading,"type":_vm.typeConfirm,"variant":_vm.showValidation && _vm.errors.first('passwordConfirm')
                        ? 'danger'
                        : ''},model:{value:(_vm.recovery.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.recovery, "passwordConfirm", $$v)},expression:"recovery.passwordConfirm"}}),_c('span',{staticClass:"absolute top-20 right-3 cursor-pointer",on:{"click":function($event){return _vm.showPassword('typeInputConfirm')}}},[_c('icon',{attrs:{"icon":_vm.typeInputConfirm === 'password' ? 'eye' : 'eye-slash'}})],1)],1),_c('t-button',{staticClass:"mt-3 w-full",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.changePassword}},[_vm._v(" Actualizar contraseña "),(_vm.isLoading)?_c('t-loader',{attrs:{"extraClass":"ml-2"}}):_vm._e()],1)],1)]:[_c('div',{staticClass:"flex items-center mt-2"},[_c('p',{staticClass:"font-light"},[_vm._v(" Déjanos tu correo de registro para poder ayudarte. "),_c('t-button',{staticClass:"p-0",attrs:{"variant":"link","to":"/iniciar_sesion","disabled":_vm.isLoading}},[_vm._v("Iniciar sesión")])],1)]),_c('div',{staticClass:"mt-8"},[_c('t-input-group',{staticClass:"mb-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('email')
                      ? _vm.errors.first('email')
                      : '',"variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"data-vv-validate-on":"blur","classes":"mb-4","name":"email","placeholder":"Correo electrónico","autocomplete":"off","disabled":_vm.isLoading,"type":"email","variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('t-button',{staticClass:"mt-3 w-full",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.send}},[_vm._v("Enviar instrucciones a mi correo "),(_vm.isLoading)?_c('t-loader',{attrs:{"extraClass":"ml-2"}}):_vm._e()],1)],1)]],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }