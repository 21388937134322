<template>
  <div class="flex" style="height: 100vh">
    <div
  class="hidden md:block md:w-1/3 bg-white"
  :style="`height: 100vh; background-image: url('/static/lif-logo-blanco.png'); background-size: 85% 50%; background-position: center; background-repeat: no-repeat;`"
></div>
    <div
      class="relative block md:flex md:flex-col w-full md:w-2/3 center justify-center bg-jevi-dark overflow-y-auto"
    >
      <div class="md:hidden">
        <router-link to="/inicio">
          <div class="w-1/5 my-4 mx-auto object-scale-down">
            <img src="/static/lif-logo.png" />
          </div>
        </router-link>
      </div>
      <div class="relative w-5/6 md:w-2/3 lg:w-7/12 mx-auto">
        <div class="relative w-full rounded-3xl p-8 bg-white shadow-md">
          <h1
            class="text-2xl text-center md:text-left md:text-5xl text-primary-800"
          >
            Reestablecer contraseña
          </h1>
          <div class="flex flex-col justify-center">
            <template v-if="token">
              <p class="font-light text-center mt-6">
                Ingresa tu nueva contraseña
              </p>

              <div class="mt-8">
                <t-input-group
                  :feedback="
                    showValidation && errors.first('password')
                      ? errors.first('password')
                      : ''
                  "
                  :variant="
                    showValidation && errors.first('password') ? 'danger' : ''
                  "
                  class="mb-2 relative"
                >
                  <t-input
                    v-validate="'required|min:6'"
                    data-vv-validate-on="blur"
                    classes="mb-4 bg-transparent"
                    v-model="recovery.password"
                    name="password"
                    placeholder="Contraseña"
                    autocomplete="off"
                    :disabled="isLoading"
                    :type="type"
                    ref="password"
                    :variant="
                      showValidation && errors.first('password') ? 'danger' : ''
                    "
                  />
                  <span
                    class="absolute top-20 right-3 cursor-pointer"
                    @click="showPassword('typeInput')"
                    ><icon
                      :icon="typeInput === 'password' ? 'eye' : 'eye-slash'"
                  /></span>
                </t-input-group>

                <t-input-group
                  :feedback="
                    showValidation && errors.first('passwordConfirm')
                      ? errors.first('passwordConfirm')
                      : ''
                  "
                  :variant="
                    showValidation && errors.first('passwordConfirm')
                      ? 'danger'
                      : ''
                  "
                  class="mb-2 relative"
                >
                  <t-input
                    v-validate="'required|confirmed:password|min:6'"
                    data-vv-validate-on="blur"
                    classes="mb-4 bg-transparent"
                    v-model="recovery.passwordConfirm"
                    name="passwordConfirm"
                    placeholder="Confirmar contraseña"
                    autocomplete="off"
                    :disabled="isLoading"
                    :type="typeConfirm"
                    :variant="
                      showValidation && errors.first('passwordConfirm')
                        ? 'danger'
                        : ''
                    "
                  />
                  <span
                    class="absolute top-20 right-3 cursor-pointer"
                    @click="showPassword('typeInputConfirm')"
                    ><icon
                      :icon="
                        typeInputConfirm === 'password' ? 'eye' : 'eye-slash'
                      "
                  /></span>
                </t-input-group>

                <t-button
                  class="mt-3 w-full"
                  @click="changePassword"
                  :disabled="isLoading"
                >
                  Actualizar contraseña
                  <t-loader v-if="isLoading" extraClass="ml-2" />
                </t-button>
              </div>
            </template>
            <template v-else>
              <div class="flex items-center mt-2">
                <p class="font-light">
                  Déjanos tu correo de registro para poder ayudarte.
                  <t-button
                    variant="link"
                    class="p-0"
                    to="/iniciar_sesion"
                    :disabled="isLoading"
                    >Iniciar sesión</t-button
                  >
                </p>
              </div>

              <div class="mt-8">
                <t-input-group
                  :feedback="
                    showValidation && errors.first('email')
                      ? errors.first('email')
                      : ''
                  "
                  :variant="
                    showValidation && errors.first('email') ? 'danger' : ''
                  "
                  class="mb-2"
                >
                  <t-input
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    classes="mb-4"
                    v-model="user.email"
                    name="email"
                    placeholder="Correo electrónico"
                    autocomplete="off"
                    :disabled="isLoading"
                    type="email"
                    :variant="
                      showValidation && errors.first('email') ? 'danger' : ''
                    "
                  />
                </t-input-group>

                <t-button
                  class="mt-3 w-full"
                  @click="send"
                  :disabled="isLoading"
                  >Enviar instrucciones a mi correo
                  <t-loader v-if="isLoading" extraClass="ml-2" />
                </t-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      user: {
        email: ""
      },
      recovery: {
        password: "",
        passwordConfirm: ""
      },
      showValidation: false,
      typeInput: "password",
      typeInputConfirm: "password",
      isLoading: false
    }
  },
  computed: {
    type() {
      return this.typeInput
    },
    typeConfirm() {
      return this.typeInputConfirm
    },
    token() {
      return this.$route.query.token || null
    }
  },
  methods: {
    showPassword(ref) {
      if (this[ref] === "password") {
        this[ref] = "text"
      } else {
        this[ref] = "password"
      }
    },
    send() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.isLoading = true
          this.$store
            .dispatch("auth/recovery", this.user)
            .then((response) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification("recovery", response.code, this.$snotify)
              })
            })
            .catch((error) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    },
    changePassword() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.isLoading = true
          this.$store
            .dispatch("auth/setPassword", {
              payload: this.recovery,
              token: this.token
            })
            .then((response) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification("setPassword", response.code, this.$snotify)
              })
            })
            .catch((error) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    }
  }
}
</script>
